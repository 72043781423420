<template>
  <div>
    <xmHeader :hasPc="hasPc" :index="0"></xmHeader>
    <indexPc v-if="hasPc" :gData="PCData"></indexPc>
    <indexMobile v-else   :gData="MobileData"></indexMobile>
    <xmFooter :hasPc="hasPc"></xmFooter>
  </div>
</template>

<script>

import platformUtils from "@/utils/platformUtils";
import xmHeader from './../../components/xmHeader/xmHeader';
import xmFooter from './../../components/xmFooter/xmFooter';
import pageData from 'data/indexData';

export default {
  name: "index",
  components: {
    xmHeader,
    xmFooter,
    indexMobile: (resolve) => {
      require(["./platform/indexMobile.vue"], resolve);
    },
    indexPc: (resolve) => {
      require(["./platform/indexPc.vue"], resolve);
    }
  },
  created() {
    this.PCData = pageData.PCData;
    this.MobileData = pageData.MobileData;
  },
  data() {
    return {
      hasPc: false,
      PCData : {},
      MobileData : {}
    };
  },
  mounted() {
    //动态设置平台
    platformUtils.getPcPlatform((hasPc) => {
      this.hasPc = hasPc;
    });
  },
};
</script>

<style>
  html,body{
  overflow-x: hidden !important;
}
</style>
