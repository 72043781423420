export default {
    PCData: {
        top: [
            {
                img: require('../assets/img/pc/index/top/img_banner_01.jpg'),
                ipadImg: require('../assets/img/pc/index/top/img_banner_ipad.jpg'),
            },
            {
                img: require('../assets/img/pc/index/top/img_banner_01.jpg'),
                ipadImg: require('../assets/img/pc/index/top/img_banner_ipad.jpg'),
            },
            {
                img: require('../assets/img/pc/index/top/img_banner_01.jpg'),
                ipadImg: require('../assets/img/pc/index/top/img_banner_ipad.jpg'),
            }
        ],
        values: [{
                title: "正直进取",
                info: "坚守底线，做正确的事情<br />对自己及团队有“要求”<br />有担当，敢于接受挑战",
                img: "//img.ibestfanli.com/xmilesWebsite2/xm_value_1.png"
            },
            {
                title: "务实求真",
                info: "接地气，在快速迭代中找到最优解<br />不自嗨，关注事实，注重结果<br />直面问题，追寻根因及本质",
                img: "//img.ibestfanli.com/xmilesWebsite2/xm_value_2.png"
            },
            {
                title: "坦诚开放",
                info: "听真话，说实话，不绕弯<br />主动反映问题，勇于承认错误 <br />乐于相互揭示盲点和问题",
                img: "//img.ibestfanli.com/xmilesWebsite2/xm_value_3.png"
            },
            {
                title: "协同创新",
                info: "相信团队，打破部门壁垒，保持主动高效沟通<br />敢于试错，在失败中成长<br />不设边界，不安于现状，寻求突破",
                img: "//img.ibestfanli.com/xmilesWebsite2/xm_value_4.png"
            },
            {
                title: "始终创业",
                info: "每件事都是自己的事<br />保持好奇心，追求客户价值提升<br />拥抱变化，对不确定性保持乐观心态",
                img: "//img.ibestfanli.com/xmilesWebsite2/xm_value_5.png"
            },
        ],
        extenets: [{
                isShowYiTxt: false,
                txtXContent: '累计开发者'
            },
            {
                isShowYiTxt: true,
                txtXContent: '累计下载量'
            },
            {
                isShowYiTxt: false,
                txtXContent: '累计产品数'
            }
        ],
        patterns: [{
                title: '数据驱动的科学立项',
                contents: [{
                        icon: '//img.ibestfanli.com/xmWebsite/pattern-content-left-font1-icon.png',
                        title: '市场行情监控',
                        content: '自主研发行情数据监控系统赋能立项，汇集海量玩法，捕获优质创意'
                    },
                    {
                        icon: '//img.ibestfanli.com/xmWebsite/pattern-content-left-font4-icon.png',
                        title: '极速MVP立项验证',
                        content: '高效完成产品组合定位及吸量测试，节约试错成本'
                    }
                ],
                image: '//img.ibestfanli.com/xmWebsite/pattern-content-left-img.png'
            },
            {
                title: '高效的研发和运营',
                contents: [{
                        icon: '//img.ibestfanli.com/xmWebsite/pattern-content-middle-icon1.png',
                        title: '产品数据分析',
                        content: '自有标准化产品数据及用户行为分析工具，赋能业务调优增长'
                    },
                    {
                        icon: '//img.ibestfanli.com/xmWebsite/pattern-content-middle-icon2.png',
                        title: '1对1的运营服务',
                        content: '每款产品均配备1对1专人优化指导'
                    },
                    {
                        icon: '//img.ibestfanli.com/xmWebsite/pattern-content-middle-icon3.png',
                        title: '开发者辅助套件',
                        content: '基于历史最佳实践的开发者辅助套件，助力产品高效迭代和交付'
                    }
                ],
                image: '//img.ibestfanli.com/xmWebsite/pattern-content-middle-img.png'
            },
            {
                title: '一站式的推广与商业化',
                contents: [{
                        icon: '//img.ibestfanli.com/xmWebsite/pattern-content-right-icon1.png',
                        title: '一站式增长服务',
                        content: ' 助力产品找到匹配用户及商业化特征的最佳成功路'
                    },
                    {
                        icon: '//img.ibestfanli.com/xmWebsite/pattern-content-middle-icon2.png',
                        title: '有效用户的成本与规模优势',
                        content: ' 精准投放及质量预估系统，抓住爆量机会'
                    },
                    {
                        icon: '//img.ibestfanli.com/xmWebsite/pattern-content-right-icon3.png',
                        title: '商业化增长分析',
                        content: '自有商业化分析系统，助力开发者实现商业化高效增长'
                    }
                ],
                image: '//img.ibestfanli.com/xmWebsite/pattern-content-right-img.png'
            },

        ]
    },


    MobileData: {
        top: [
            {
                img: require('../assets/img/mobile/index/top/img_bannner.png'),
            },
            {
                img: require('../assets/img/mobile/index/top/img_bannner.png'),
            },
            {
                img: require('../assets/img/mobile/index/top/img_bannner.png'),
            }
        ],
        values: [{
                title: "正直进取",
                info: "坚守底线，做正确的事情<br />对自己及团队有“要求”<br />有担当，敢于接受挑战",
                img: "//img.ibestfanli.com/xmilesWebsite2/xm-value-1.png"
            },
            {
                title: "务实求真",
                info: "接地气，在快速迭代中找到最优解<br />不自嗨，关注事实，注重结果<br />直面问题，追寻根因及本质",
                img: "//img.ibestfanli.com/xmilesWebsite2/xm-value-2.png"
            },
            {
                title: "坦诚开放",
                info: "听真话，说实话，不绕弯<br />主动反映问题，勇于承认错误 <br />乐于相互揭示盲点和问题",
                img: "//img.ibestfanli.com/xmilesWebsite2/xm-value-6.png"
            },
            {
                title: "协同创新",
                info: "相信团队，打破部门壁垒，保持主动高效沟通<br />敢于试错，在失败中成长<br />不设边界，不安于现状，寻求突破",
                img: "//img.ibestfanli.com/xmilesWebsite2/xm-value-4.png"
            },
            {
                title: "始终创业",
                info: "每件事都是自己的事<br />保持好奇心，追求客户价值提升<br />拥抱变化，对不确定性保持乐观心态",
                img: "//img.ibestfanli.com/xmilesWebsite2/xm-value-5.png"
            },
        ],
        extenets: [{
                isShowYiTxt: false,
                txtXContent: '平均日活跃用户',
                num: 110
            },
            {
                isShowYiTxt: true,
                txtXContent: '年度总新增用户',
                num: 5
            },
            {
                isShowYiTxt: false,
                txtXContent: '累计开发产品数',
                num: 200
            }
        ],
        patterns: [{
                title: '数据驱动的科学立项',
                contents: [{
                        icon: '//img.ibestfanli.com/xmWebsite/pattern-content-left-font1-icon.png',
                        title: '市场行情监控',
                        content: '自主研发行情数据监控系统赋能立项，汇集海量玩法，捕获优质创意'
                    },
                    {
                        icon: '//img.ibestfanli.com/xmWebsite/pattern-content-left-font4-icon.png',
                        title: '极速MVP立项验证',
                        content: '高效完成产品组合定位及吸量测试，节约试错成本'
                    }
                ],
                image: '//img.ibestfanli.com/xmWebsite/pattern-content-left-img.png'
            },
            {
                title: '高效的研发和运营',
                contents: [{
                        icon: '//img.ibestfanli.com/xmWebsite/pattern-content-middle-icon1.png',
                        title: '产品数据分析',
                        content: '自有标准化产品数据及用户行为分析工具，赋能业务调优增长'
                    },
                    {
                        icon: '//img.ibestfanli.com/xmWebsite/pattern-content-middle-icon2.png',
                        title: '1对1的运营服务',
                        content: '每款产品均配备1对1专人优化指导'
                    },
                    {
                        icon: '//img.ibestfanli.com/xmWebsite/pattern-content-middle-icon3.png',
                        title: '开发者辅助套件',
                        content: '基于历史最佳实践的开发者辅助套件，助力产品高效迭代和交付'
                    }
                ],
                image: '//img.ibestfanli.com/xmWebsite/pattern-content-middle-img.png'
            },
            {
                title: '一站式的推广与商业化',
                contents: [{
                        icon: '//img.ibestfanli.com/xmWebsite/pattern-content-right-icon1.png',
                        title: '一站式增长服务',
                        content: ' 助力产品找到匹配用户及商业化特征的最佳成功路'
                    },
                    {
                        icon: '//img.ibestfanli.com/xmWebsite/pattern-content-middle-icon2.png',
                        title: '有效用户的成本与规模优势',
                        content: ' 精准投放及质量预估系统，抓住爆量机会'
                    },
                    {
                        icon: '//img.ibestfanli.com/xmWebsite/pattern-content-right-icon3.png',
                        title: '商业化增长分析',
                        content: '自有商业化分析系统，助力开发者实现商业化高效增长'
                    }
                ],
                image: '//img.ibestfanli.com/xmWebsite/pattern-content-right-img.png'
            },

        ]
    }
}