const platformUtils = {
    getPcPlatform(callback){
        window.onresize = function () {
            callback(platformUtils.hasPcPlatform());
        }
        callback(platformUtils.hasPcPlatform());
    },
    //是否 PC 平台
    hasPcPlatform(){
        let htmlWidth = document.querySelector('html').clientWidth;
        // return htmlWidth > 750;
        // return htmlWidth >= 768;
        return htmlWidth >= 700;
    }
}
export default platformUtils;