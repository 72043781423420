<template>
  <headerPc v-if="hasPc" :gData="gData"></headerPc>
  <headerMobile v-else :gData="gData"></headerMobile>
</template>

<script>
import Config from 'utils/Config';
export default {
  name: 'xmHeader',
  props: {
    hasPc: Boolean,
    index: Number
  },
  data() {
    return {
      gData: {
        index: this.index,
        logo: require('../../assets/logo.png'),
        tabIcon: '//img.ibestfanli.com/xmOfficial/tabIcon.png',
        tabClose: '//img.ibestfanli.com/xmOfficial/tabClose.png',
        developerLogin:
          'http://it.yingzhongshare.com/index-frontend/index.html#/login',
        btns: [
          { name: '首页', url: `${Config.domain}/index.html` },
          { name: '应用', url: `${Config.domain}/app.html` },
          { name: '关于我们', url: `${Config.domain}/about.html` },
          { name: '加入我们', url: `${Config.domain}/hire.html` }
        ]
      }
    };
  },
  components: {
    headerMobile: resolve => {
      require(['./platform/headerMobile.vue'], resolve);
    },
    headerPc: resolve => {
      require(['./platform/headerPc.vue'], resolve);
    }
  }
};
</script>
<style lang="scss" scoped></style>
